import triggerResize from '../../../assets/scripts/helpers/resize-trigger.js';
import collectUrlParams from '../../../assets/scripts/helpers/collect-url-params.js';

/***
 * This module is initialised:
 * - on page load: then all load more buttons are initialised.
 * - when filters get changed: then only the button inside the specified btnWrapper is initialised.
 *
 * If the page has no buttons, nothing will happen.
 *
 * @param btnWrapper: is the parent of the button which will be initialised
 */


export default function (btnWrapper) {

  if (typeof btnWrapper === "undefined") {
    const modules = document.getElementsByClassName('js-load-more');

    if (modules.length === 0) return;

    Array.prototype.forEach.call(modules, function(module) {
      const button = module.querySelector('button');
      button.addEventListener('mouseup', loadMore);
    });

  } else {
    const button = btnWrapper.querySelector("button");
    if (btnWrapper.classList.contains("js-load-more-search")) {
      // console.log("setEventHandler click button", button);
      button.addEventListener("click", loadMore);
    } else {
      button.addEventListener("mouseup", loadMore);
    }
  }


  function setEventHandler(contentWrapper) {
    const loadMoreBtn = contentWrapper.querySelector('.js-load-more button');
    if (loadMoreBtn) {
      loadMoreBtn.removeEventListener('mouseup', loadMore);
      loadMoreBtn.addEventListener('mouseup', loadMore);
    }
    const loadMoreBtnSearch = contentWrapper.querySelector(".js-load-more-search button");
    if (loadMoreBtnSearch) {
      //console.log("setEventHandler loadMoreBtnSearch", loadMoreBtnSearch);
      loadMoreBtnSearch.removeEventListener("click", loadMore);
      loadMoreBtnSearch.addEventListener("click", loadMore);
    }
  }

  function renderData(data, button) {
    const buttonWrapper = button.parentNode;
    if (buttonWrapper.classList.contains("news-archive")) {
      renderJSONData(data, buttonWrapper);
    } else {
      const contentWrapper = button.parentNode.parentNode;
      if (contentWrapper) {
        contentWrapper.insertAdjacentHTML('beforeend', data);
        contentWrapper.removeChild(buttonWrapper);
        setEventHandler(contentWrapper);
        triggerResize();
      }
    }
  }

  function renderJSONData(data, buttonWrapper) {
    console.log("** renderJSONData **");
    const contentWrapper = buttonWrapper.parentNode;
    if (contentWrapper) {
      let dataInitUrl = buttonWrapper.getAttribute("data-init-url");
      const dataTab = buttonWrapper.getAttribute("data-for-tab");
      const isMansoryTab = contentWrapper.classList.contains("mansonry");
      const socialCardContainer = contentWrapper.querySelector(
        ".social-cards-container"
      );
      if (dataInitUrl !== null) {
        dataInitUrl = collectUrlParams(dataInitUrl, dataTab);
        const urlParams = new URLSearchParams(dataInitUrl);
        // console.log("jgdebug urlParams", urlParams.get("tab"));
        if (urlParams.get("page") !== null) {
          const pageNumber = parseInt(urlParams.get("page"));
          const dataInitUrlLoadmore = dataInitUrl.replace(
            "page=" + pageNumber,
            "page=" + (pageNumber + 1)
          );
          buttonWrapper.setAttribute("data-init-url", dataInitUrlLoadmore);
        } else {
          const dataInitUrlLoadmore = dataInitUrl + "&page=2";
          buttonWrapper.setAttribute("data-init-url", dataInitUrlLoadmore);
        }
      }
      const socialTypes = [
        "facebook",
        "twitter",
        "linkedin",
        "youtube",
        "instagram",
      ];
      data = JSON.parse(data);
      const cards = data.data.sort((a, b) => {
        if (a.publishingdate === b.publishingdate) {
          const differenceinminA = parseInt(a.differenceinmin);
          const differenceinminB = parseInt(b.differenceinmin);
          return differenceinminA - differenceinminB;
        }
        return new Date(b.publishingdate) - new Date(a.publishingdate);
      });
      console.log("jgdebug newsjson", cards);
      cards.forEach((card) => {
        const cardType = card.type;
        const cardLabel = card.label;
        let dataDimensions = "1.7777777777777777";
        let socialClass = "card--owcs";
        let socialTypeClass = "";
        let gridRowEndClass = "";
        let linkTarget = "_self";
        const cardWithImage = card.image !== null && card.image !== "" && card.image !== undefined ? true : false;
        if (cardWithImage) {
          gridRowEndClass = "col--2x";
        } else if (cardType == "News") {
          gridRowEndClass = "col--2x";
        } else if (cardType == "InternalBlog") {
          gridRowEndClass = "col--2x";
        } else if (cardType == "FrontBlogPost") {
          gridRowEndClass = "col--2x";
        }
        let cardTitle = "";
        // convert minutes to hours
        const differenceinmin = card.differenceinmin;
        let cardTime = card.publishingdate;
        if (differenceinmin && differenceinmin > 0) {
          if (differenceinmin && differenceinmin < 60) {
            cardTime = differenceinmin + " Minuten";
          } else if (differenceinmin && differenceinmin < 1440) {
            const hours = Math.floor(differenceinmin / 60);
            cardTime = hours + " Stunden";
          } else if (
            differenceinmin &&
            differenceinmin >= 1440 &&
            differenceinmin < 2880
          ) {
            cardTime = "gestern";
          }
        }
        if (socialTypes.includes(cardType)) {
          linkTarget = "_blank";
          dataDimensions = "1.3333333333333333";
          if (cardType === "instagram") {
            dataDimensions = "1";
            gridRowEndClass = "col--2x";
          } else if (cardType === "facebook") {
            dataDimensions = "1";
            gridRowEndClass = "col--2x";
          }
          socialClass = "card--wallsio";
          socialTypeClass = "card--" + cardType;
        } else {
          cardTitle = `<h3>${card.title}</h3>`;
        }
        let contentTemplate = `<a href="${card.href}" class="card ${socialClass} ${gridRowEndClass} ${socialTypeClass}" data-target="card" target="${linkTarget}">`;
        if (cardWithImage) {
          contentTemplate += `<figure>
				    <img data-dimensions="${dataDimensions}" alt="${card.title}" title="${card.title}" width="auto" height="auto" src="${card.image}" fetchpriority="high" decoding="async">
				  </figure>`;
        }
        let labelClass = cardType === "News"  ? "press" : cardType.toLowerCase();
        let cardTextTemplate = `<div class="card__text">
                  <div>
                    ${cardTitle}
                    <p>
                      ${card.leadin}
                    </p>
                  </div>
                </div>`;
        if (card.usermap !== null && card.usermap !== undefined && card.usermap !== "") {
          const authorPhotoURL = card.usermap.authorPhotoURL;
          const encodedPhoto = card.usermap.encodedPhoto;
          const usermapTitle = card.usermap.title !== null && card.usermap.title !== undefined && card.usermap.title !== "" ? card.usermap.title : "";
          const usermapCompany = card.usermap.omvCompany !== null && card.usermap.omvCompany !== undefined && card.usermap.omvCompany !== "" ? card.usermap.omvCompany : "";
          const authorImage = encodedPhoto !== null && encodedPhoto !== undefined && encodedPhoto !== "" ? `data:image/jpeg;base64,${encodedPhoto}` : authorPhotoURL;
          cardTextTemplate = `<div class="card__text">
                  <div>
                    <div class="card__author">
                        <figure class="author-image">
                          <img src="${authorImage}" data-dimensions="1" alt="" width="auto" height="auto"/>
                        </figure>
                      <div class="card__author-info">
                        <small>von <strong>${card.usermap.givenName} ${card.usermap.sn}</strong></small>
                        <small>${usermapTitle}</small>
                        <small>${usermapCompany}</small>
                      </div>
                    </div>
                    ${cardTitle}
                    <p>
                      ${card.leadin}
                    </p>
                  </div>
                </div>`;
        }
        contentTemplate += `<div class="card__meta">
					  <small class="card__date">${cardTime}</small>
					  <div class="card__spacer"></div>
					  <small class="context-label context-label--${labelClass}">${cardLabel}</small>
					</div>
					${cardTextTemplate}
				  </a>`;
          if (isMansoryTab) {
            if (socialCardContainer !== null) {
              socialCardContainer.insertAdjacentHTML(
                "beforeend",
                contentTemplate
              );
            }
          } else {
            contentWrapper.insertAdjacentHTML("beforeend", contentTemplate);
          }
      });
      buttonWrapper.classList.remove("loading");
      const button = buttonWrapper.querySelector("button");
      if (button) {
        const showMore = data.showmore;
        if (showMore !== null && showMore !== undefined && showMore === true) {
          if (button.classList.contains("hide")) {
            button.classList.remove("hide");
          }
        } else {
          button.classList.add("hide");
        }
      }
      triggerResize();
    }
  }

  function renderSearchData(data, button) {
    console.log("jgdebug renderSearchData X", data);
    const buttonWrapper = button.parentNode;
    const contentWrapper = button.parentNode.parentNode;
    const hideFilters = buttonWrapper.classList.contains("hide-filter");
    const typeLabel = buttonWrapper.getAttribute("data-label");
    const gridSpanFirst = document.querySelector("#grid-1");
    const gridSpanSecond = document.querySelector("#grid-2");
    const gridSpanThird = document.querySelector("#grid-3");
    if (hideFilters === true) {
      // console.debug("jgdebug hide Filters");
      if (gridSpanFirst !== null) {
        gridSpanFirst.classList.add("hide-on--all");
      }
      if (gridSpanSecond !== null) {
        gridSpanSecond.classList.add("hide-on--all");
      }
      if (gridSpanThird !== null) {
        gridSpanThird.classList.remove("span-8");
        gridSpanThird.classList.add("span-10");
      }
    } else {
      // console.debug("jgdebug show Filters");
      if (gridSpanFirst !== null) {
        gridSpanFirst.classList.remove("hide-on--all");
      }
      if (gridSpanSecond !== null) {
        gridSpanSecond.classList.remove("hide-on--all");
      }
      if (gridSpanThird !== null) {
        gridSpanThird.classList.add("span-8");
        gridSpanThird.classList.remove("span-10");
      }
    }
    if (contentWrapper) {
      let searchResultsMarkup = ``;
      if (data.searchresult > 0) {
        data.results.forEach((result) => {
          if (result.subtype) {
            let resultSubtype = result.subtype.toLowerCase();
            let resultSubTypeLabel = typeLabel;
            const contentTemplate = `<a href="${result._link_}" class="list ">
                <div class="card__text">
                  <div>
                    <h3>${result.title}</h3>
                    <p>${result.leadin}</p>
                  </div>
                </div>
                <div class="card__meta">
                  <small class="context-label context-label--${resultSubtype}">${resultSubTypeLabel}</small>
                </div>
              </a>
            `;
            searchResultsMarkup += contentTemplate;
          } else {
            const authorjobtitle = result.authorjobtitle ? result.authorjobtitle : "";
            const address = result.address ? result.address : "";
            const link = result.link ? result.link : "";
            const phonenumber = result.phonenumber ? "Tel: " + result.phonenumber : "";
            const faxnumber = result.faxnumber ? "Fax: " + result.faxnumber : "";
            const emailaddress = result.emailaddress ? "Mail: " + result.emailaddress : "";
            const portrait = result.authorimage ? `
              <div class="portrait">
                <img data-src="${result.authorimage.patternurl}" data-dimensions="0.75" alt="" width="auto" height="auto">
              </div>
            `
              : ``;
            const contentTemplate = `<div class="details--wrapper">
                    <div class="contact--detail-container">
                        <div class="contact-name--container">
                            <div class="contact-name">${result.authorname}</div>
                            <div class="shoptitle">${authorjobtitle}</div>
                            <div class="organisation"></div>
                        </div>
                        <div class="remote">
                            <div class="postal">
                                <div class="address">${address}</div>
                                <div class=" link">
                                  <a href="${link}">Link</a>
                                </div>
                            </div>
                            <div class="postal">
                              <div class="phone">
                                  <a href="tel:${result.phonenumber}">${phonenumber}</a>
                              </div>
                              <div class="fax">${faxnumber}</div>
                              <div class="mail">
                                  <a href="mailto:${result.emailaddress}">${emailaddress}</a>
                              </div>
                            </div>
                        </div>
                    </div>
                    ${portrait}
                  </div>
            `;
            searchResultsMarkup += contentTemplate;
          }
        });
      }
      const searchResults = document.querySelector("strong#searchResults");
      if (searchResults !== null) {
        searchResults.innerHTML = data.searchresult;
      }
      const searchText = document.querySelector("strong#searchText");
      if (searchText !== null) {
        searchText.innerHTML = '"' + data.searchText + '" in ' + typeLabel;
      }
      buttonWrapper.classList.remove("loading");
      const searchPageResultText = document.querySelector("p.search-page__results-count");
      if (searchPageResultText !== null) {
        searchPageResultText.classList.remove("hide");
      }
      // buttonWrapper.setAttribute("data-url", url);
      if (data.showMore === false) {
        buttonWrapper.classList.add("hide-on--all");
      } else {
        buttonWrapper.classList.remove("hide-on--all");
      }
      searchResultsMarkup += `${buttonWrapper.outerHTML}`;
      contentWrapper.removeChild(buttonWrapper);
      if (data.searchresult > 0) {
        if (buttonWrapper.hasAttribute("data-reset")) {
          contentWrapper.innerHTML = searchResultsMarkup;
          buttonWrapper.removeAttribute("data-reset");
        } else {
          contentWrapper.insertAdjacentHTML("beforeend", searchResultsMarkup);
        }
      } else {
        contentWrapper.innerHTML = searchResultsMarkup;
      }
      console.log("jgdebug has Attribute filter>", buttonWrapper.hasAttribute("data-filter"));
      setEventHandler(contentWrapper);
      triggerResize();
    }
  }

  function loadMore() {
    const buttonWrapper = this.parentNode;
    buttonWrapper.classList.add("loading");
    let url = buttonWrapper.getAttribute("data-url") !== null ? buttonWrapper.getAttribute("data-url") : buttonWrapper.getAttribute("data-init-url");
    // console.debug("jgdebug loadMore hi", buttonWrapper.getAttribute("data-filter"));
    if (buttonWrapper.classList.contains("js-load-more-search")) {
      const searchPageResultText = document.querySelector("p.search-page__results-count");
      if (searchPageResultText !== null) {
        searchPageResultText.classList.add("hide");
      }
      if (buttonWrapper.hasAttribute("data-filter")) {
        if (buttonWrapper.getAttribute("data-filter") !== "init") {
          url = url + buttonWrapper.getAttribute("data-filter");
        }
        //  buttonWrapper.removeAttribute("data-filter");
      } else if (buttonWrapper.hasAttribute("data-page-number")) {
        const pageNumber = buttonWrapper.getAttribute("data-page-number");
        const prePageNumber = parseInt(pageNumber) + 1;
        url = url + "&pageNumber=" + prePageNumber;
        buttonWrapper.setAttribute("data-page-number", "" + prePageNumber);
      } else {
        url = url + "&pageNumber=2";
        buttonWrapper.setAttribute("data-page-number", "2");
      }
      console.log("jgdebug loadMore url", url);
      fetch(url)
        .then((r) => r.json())
        .then((data) => renderSearchData(data, this))
        .catch((e) =>
          console.error("loadMoreSearch: fetch returned error: " + e)
        );
    } else {
      const dataTab = buttonWrapper.getAttribute("data-for-tab");
      url = collectUrlParams(url, dataTab, false);
      fetch(url)
        .then((r) => r.text())
        .then((data) => renderData(data, this))
        .catch((e) => console.error("loadMore: fetch returned error: " + e));
    }
  }
}
