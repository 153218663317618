import { is } from "core-js/core/object";

let $searchField = $(".js-content-filter-view .js-search-field");
let $dateFrom = $(".js-content-filter-view .js-date-from");
let $dateTo = $(".js-content-filter-view .js-date-to");
let $blogCategorySelect = $(".js-content-filter-view .js-blog-category");
let $blogTagSelect = $(".js-content-filter-view .js-blog-tag");
let $blogLocationSelect = $(".js-content-filter-view .js-blog-location");
let $socialWallFilter = $(".js-content-filter-view .js-social-wall-filter");

/**
 * Don't send the search phrase to the server if it's shorter than 3 characters.
 * @returns {string}
 */
function getSearchPhrase() {
  return $searchField.val().length < 3 ? "" : $searchField.val();
}

/**
 * Collect all values for the query.
 * Add the search phrase to the query string.
 *
 * We assume, that search and date-range filter are used in all tabs.
 * All the other filters may be tab-specific and should be applied by the tab they uses it only.
 *
 * Collect the values of all select boxes, tag-triggers and date fields and add them to the query string.
 * Set the page parameter to 1 because we should always start with the first page when we change the filters/search.
 */
function collectUrlParams(dataUrl, tab, isFilter) {
  if(isFilter){
    dataUrl = replaceParam(dataUrl, "&page=", 1);
  }
  dataUrl = replaceParam(dataUrl, "&dateFrom=", $dateFrom.val() || "");
  dataUrl = replaceParam(dataUrl, "&dateTo=", $dateTo.val() || "");
  dataUrl = replaceParam(dataUrl, "&search=", getSearchPhrase());
  if ($blogCategorySelect.length) {
    let blogCategoryValue = $blogCategorySelect.val();
    dataUrl = replaceParam(dataUrl, "&blogcategory=", blogCategoryValue);
  }
  if ($blogTagSelect.length) {
    let blogTagValue = $blogTagSelect.val();
    dataUrl = replaceParam(dataUrl, "&blogtags=", blogTagValue);
  }
  if ($blogLocationSelect.length) {
    let blogLocationValue = $blogLocationSelect.val();
    dataUrl = replaceParam(dataUrl, "&location=", blogLocationValue);
  }
  if ($socialWallFilter.length) {
    let socialWallValue = $socialWallFilter.val();
    dataUrl = replaceParam(dataUrl, "&wfilter=", socialWallValue);
  }

  console.log("jgdebug collectUrlParams tab", tab);
  console.log("jgdebug collectUrlParams dataUrl", dataUrl);

  // gather custom filters, tab-wise:
  $(
    ".js-content-filter-view .js-content-filters .js-content-filter--custom--" +
      tab
  ).each(function () {
    // this is a tag filter
    if ($(this).find(".filter__tags").length === 1) {
      const name = $(this).find(".filter__tags").data("name");
      let arr = [];
      $(this)
        .find(".js-tag-trigger.is-active")
        .each(function () {
          const val = $(this).data("value") || $(this).text();
          arr.push(val);
        });
      dataUrl = replaceParam(dataUrl, "&" + name + "=", arr.join());
    }

    // this is a dropdown filter
    if ($(this).find("select").length === 1) {
      const sel = $(this).find("select");
      dataUrl = replaceParam(dataUrl, "&" + sel.prop("name") + "=", sel.val());
    }
  });
  return dataUrl;
}

/**
	 * Set the url parameter and their values
	 *
	 * @param dataUrl: the url to the template which will send the data to the client
	 * @param paramString: the current param, for example "&category="
	 * @param value
	 * @returns {*}
	 */
	function replaceParam(dataUrl, paramString, value) {

		let start = dataUrl.indexOf(paramString);
		if (start !== -1) {
			// found the param -> replace it's value
			start = start + paramString.length; // here starts the value for this param

			// now get the first "&" after the current param
			const sub = dataUrl.substring(start, dataUrl.length);
			let end = sub.indexOf("&");
			if (end === -1) {
				end = dataUrl.length;
			}
			//assamble the new url
			dataUrl = dataUrl.substring(0, start) + value + dataUrl.substring(start + end, dataUrl.length);

		} else {
			// param not found, attach it to the url
			dataUrl += paramString + value;
		}
		return dataUrl;
	}

export default collectUrlParams;
export {
  collectUrlParams,
  $dateFrom,
  $dateTo,
  $searchField,
};
